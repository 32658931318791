/* eslint-disable react/no-danger */
import React, { useCallback, useState } from 'react';
import { useMutation } from 'react-query';
import { Outlet, useNavigate, useSearchParams } from 'react-router-dom';
import {
  Loading, Typography, Image, Button
} from 'tfc-components';

import boxModal from 'assets/images/box-modal-l.png';
import logo from 'assets/images/logo2.png';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import useDidMount from 'hooks/useDidMount';
import { mappingErrorLocationGetter } from 'services/common/errors';
import { getLocalStorage } from 'services/common/storage';
import { checkinService } from 'services/customer';
import { checkinPubService } from 'services/pub';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { closeLocationModal, openLocationModal } from 'store/system';
import { getProfileAction, saveProfile } from 'store/user';
import { COLORS, LOCALSTORAGE_KEYS, ROUTES_PATH } from 'utils/constants';
import mapModifiers, { getLocation } from 'utils/functions';

const Mainlayout: React.FC = () => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');

  const general = useAppSelector((state) => state.system.data);
  const locationModal = useAppSelector((state) => state.system.locationModal);

  const [loading, setLoading] = useState(false);
  const [locationNotice, setLocationNotice] = useState({
    trigger: false,
    error: 'PERMISSION_DENIED' as LocationErrorType
  });
  const [globalErr, setGlobalErr] = useState<string | undefined>();
  const [exist, setExist] = useState<boolean>(false);
  const maxPoint = useCallback((region?: RegionType) => {
    switch (region) {
      case 'northern':
        return (general?.others.hipoint || 0) * (general?.others.checkinNorthernRegion || 0);
      case 'central':
        return (general?.others.hipoint || 0) * (general?.others.checkinCentralRegion || 0);
      case 'southern':
        return (general?.others.hipoint || 0) * (general?.others.checkinSouthernRegion || 0);
      default:
        return 0;
    }
  }, [general]);
  const { mutate: checkinMutate } = useMutation('checkin', checkinService, {
    onSuccess(data) {
      dispatch(saveProfile(data.customerData));
      navigate(`${ROUTES_PATH.PROFILE}?id=${pubId}`);
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          switch (err.code) {
            case '9525':
              setExist(true);
              setGlobalErr(`Vui lòng quét mã QR<br />ở những quán khác để tích luỹ<br />đủ ${maxPoint(err.detail as RegionType | undefined)} HIPOINTS<br />và nhận thưởng.`);
              break;
            case '9524':
              setGlobalErr('Vị trí Checkin tại quán<br />không hợp lệ.');
              break;
            case '9526':
              setGlobalErr(`Thiết bị này đã được đăng ký<br />tham gia với số điện thoại<br />${err.detail} rồi`);
              break;
            case '9521':
              setGlobalErr('Số điện thoại này đã được<br />tham gia bởi thiết bị khác.');
              break;
            case '9527':
              navigate(`${ROUTES_PATH.QRCODE}?id=${pubId}`);
              break;
            default:
              setGlobalErr('Đã có lỗi xảy ra!');
              break;
          }
        });
      } else if (error.response.status === 404) {
        navigate(`${ROUTES_PATH.HOME}?id=${pubId}`);
      } else {
        setGlobalErr(`Đã có lỗi(${error.response.status}) xảy ra!`);
      }
    },
  });
  const { mutate: checkinPubMutate } = useMutation('checkinPub', checkinPubService, {
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          switch (err.code) {
            case '9525':
              setExist(true);
              setGlobalErr(`Vui lòng quét mã QR<br />ở những quán khác để tích luỹ<br />đủ ${maxPoint(err.detail as RegionType | undefined)} HIPOINTS<br />và nhận thưởng.`);
              break;
            case '9524':
              setGlobalErr('Vị trí Checkin tại quán<br />không hợp lệ.');
              break;
            case '9526':
              setGlobalErr(`Thiết bị này đã được đăng ký<br />tham gia với số điện thoại<br />${err.detail} rồi`);
              break;
            case '9521':
              setGlobalErr('Số điện thoại này đã được<br />tham gia bởi thiết bị khác.');
              break;
            case '9527':
              navigate(`${ROUTES_PATH.QRCODE}?id=${pubId}`);
              break;
            default:
              setGlobalErr('Đã có lỗi xảy ra!');
              break;
          }
        });
      } else if (error.response.status === 404) {
        setGlobalErr('Vị trí Checkin tại quán<br />không hợp lệ.');
      } else {
        setGlobalErr(`Đã có lỗi(${error.response.status}) xảy ra!`);
      }
    },
  });

  const checkin = useCallback(() => {
    const userSaved = getLocalStorage(LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER);
    if (!userSaved || !pubId) {
      return;
    }
    const userParser = JSON.parse(userSaved) as UserSavedLocal;
    const { phone, deviceId } = userParser;
    setLoading(true);
    getLocation().then((res) => {
      checkinMutate({
        pubId: Number(pubId), phone, deviceId, lat: res.lat, long: res.long, scanDeviceId: false
      });
      setLoading(false);
    }).catch((error: GeolocationPositionError) => {
      setLocationNotice({ trigger: true, error: mappingErrorLocationGetter(error.code) });
      setLoading(false);
    });
  }, [checkinMutate, pubId]);

  const checkinPub = useCallback(() => {
    if (!pubId) {
      return;
    }
    setLoading(true);
    getLocation().then((res) => {
      checkinPubMutate({ pubId: Number(pubId), lat: res.lat, long: res.long });
      setLoading(false);
    }).catch((error: GeolocationPositionError) => {
      setLocationNotice({ trigger: true, error: mappingErrorLocationGetter(error.code) });
      setLoading(false);
    });
  }, [checkinPubMutate, pubId]);

  useDidMount(() => {
    const userSaved = localStorage.getItem(LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER);
    if (userSaved) {
      const userParser = JSON.parse(userSaved) as UserSavedLocal;
      dispatch(getProfileAction(userParser.phone)).unwrap().then((data) => {
        if (data.receivedGift) {
          navigate(`${ROUTES_PATH.CONGRATULATION}?id=${pubId}`);
        } else {
          checkin();
        }
      });
    } else {
      checkinPub();
    }
  });
  return (
    <main className="main">
      {(loading) && (
        <div className="o-pageLayout_loading">
          <Loading.CircleDashed extendClasses="o-pageLayout_loading_indicator" color={COLORS.white} width={48} />
        </div>
      )}
      {pubId ? <Outlet /> : (
        <PageLayout>
          <Image extendClasses="main-logo" imgSrc={logo} alt="Chivas" ratio={343 / 28} />
          <div className="main-notfound">
            <Typography.Text
              extendClasses="main-notfound_text"
              fontweight="700"
            >
              Vui lòng dùng thiết bị di động
              <br />
              quét Mã QR ở quán
              <br />
              để tham gia chương trình.
            </Typography.Text>
          </div>
        </PageLayout>
      )}
      <CustomModal isOpen={locationNotice.trigger}>
        <div className="popup-notice">
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">THÔNG BÁO</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            {(() => {
              switch (locationNotice.error) {
                case 'PERMISSION_DENIED':
                  return (
                    <>
                      Xin lỗi, bạn cần cho phép truy cập
                      <br />
                      vị trí để có thể tham gia
                      <br />
                      chương trình.
                      <br />
                      Xin vui lòng cấp quyền
                      <br />
                      truy cập vị trí trong phần cài đặt
                      <br />
                      điện thoại để có thể tiếp tục
                      <br />
                      tham gia.
                    </>
                  );
                case 'POSITION_UNAVAILABLE':
                  return (
                    <>
                      Không thể xác định vị trí của bạn.
                      <br />
                      Vui lòng thử lại.
                    </>
                  );
                default:
                  return (
                    <>
                      Đã hết thời gian xử lý.
                      <br />
                      Vui lòng thử lại
                    </>
                  );
              }
            })()}
          </Typography.Text>
          <div onClick={() => {
            dispatch(openLocationModal());
            setLocationNotice({ trigger: false, error: 'PERMISSION_DENIED' });
          }}
          >
            <Typography.Text extendClasses="popup-notice_location" fontweight="600">
              Hướng dẫn bật vị trí
            </Typography.Text>
          </div>
          <div
            className="popup-notice_confirm"
            role="button"
            onClick={() => setLocationNotice({ trigger: false, error: 'PERMISSION_DENIED' })}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐỒNG Ý</Typography.Text>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={!!globalErr}>
        <div className={`${mapModifiers('popup-notice', exist && 'exist')} error`}>
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">{exist ? 'Mã QR đã được sử dụng' : 'THÔNG BÁO'}</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            <span dangerouslySetInnerHTML={{ __html: globalErr || '' }} />
          </Typography.Text>
          <div
            className="popup-notice_confirm error"
            role="button"
            onClick={() => {
              if (exist) {
                navigate(`${ROUTES_PATH.PROFILE}?id=${pubId}`);
                setGlobalErr(undefined);
              } else {
                setGlobalErr(undefined);
              }
            }}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐÓNG</Typography.Text>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={locationModal || false}>
        <div className="p-home_policy">
          <Image imgSrc={boxModal} alt="Policy" ratio={343 / 563} />
          <div className="p-home_policy_content">
            <Typography.Text extendClasses="p-home_policy_title" fontweight="600">
              Hướng dẫn
              <br />
              bật truy cập vị trí
            </Typography.Text>
            <Typography.Text extendClasses="p-home_policy_text">
              <br />
              <strong>iOS:</strong>
              <br />
              <br />
              1. Vào cài đặt &gt; Chọn quyền riêng tư &gt;
              Bật dịch vụ định vị &gt; Chọn bật, tắt định vị cho từng ứng dụng theo ý muốn
              <br />
              <br />
              2. Vào cài đặt &gt; Safari &gt; Vị trí &gt; Chọn Cho phép
              <br />
              <br />
              <br />
              <strong>Android:</strong>
              <br />
              <br />
              Vào cài đặt &gt; Chọn phần Vị trí &gt; Chọn bật ON &gt; Nếu có thông báo yêu
              cầu  xác  nhận,  bạn click tiếp Đồng ý.
            </Typography.Text>
            <div className="p-home_policy_button">
              <Button
                variant="primary"
                primaryColor="#00E151"
                onClick={() => dispatch(closeLocationModal())}
              >
                <p className="p-home_policy-textButton">
                  Đóng
                </p>
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>
    </main>
  );
};

export default Mainlayout;
