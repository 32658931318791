import React, { useState } from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Button, Image, Typography } from 'tfc-components';

import box from 'assets/images/box-modal-l.png';
import imgFooter from 'assets/images/chivas-white.png';
import logo from 'assets/images/logo1.png';
import btnNo from 'assets/images/no.png';
import btnYes from 'assets/images/yes.png';
import Checkbox from 'components/atoms/Checkbox';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import { ROUTES_PATH } from 'utils/constants';

const contentPolicy = `
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">Chào mừng bạn đến với <strong>PRVN!</strong></span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">Các Điều khoản và Điều kiện này phác thảo các quy tắc và quy định cho việc sử dụng trang web của <strong>PRVN,</strong> được đặt tại </span><a href="www.crhiball.vn"><span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">www.crhiball.vn</span></a>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">Bằng cách truy cập trang web này, chúng tôi cho rằng bạn chấp nhận các Điều khoản và điều kiện này. Không tiếp tục sử dụng <strong>PRVN </strong>nếu bạn không đồng ý thực hiện tất cả các điều khoản và điều kiện được nêu trên trang này.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:13.5pt;"><strong>Điều 1: Định nghĩa</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">“Dữ liệu cá nhân” có nghĩa là thông tin liên quan đến khách hàng của <strong>PRVN</strong>, bao gồm họ tên, địa chỉ, số điện thoại, email và các thông tin cá nhân khác. <strong>“Bên cung cấp” </strong>có nghĩa là các đối tác, khách hàng cung cấp thông tin cho <strong>PRVN.</strong></span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:13.5pt;"><strong>Điều 2: Nội dung bảo vệ dữ liệu cá nhân</strong></span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>1. Phạm vi áp dụng:&nbsp;</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">Điều này áp dụng cho toàn bộ dữ liệu cá nhân của khách hàng được <strong>PRVN </strong>thu thập và xử lý,</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">bao gồm:</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Dữ liệu định danh: Họ tên, năm sinh, giới tính, số CMND/CCCD, địa chỉ thường trú.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Dữ liệu liên hệ: số điện thoại, email.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Dữ liệu tài chính: thông tin thẻ ngân hàng, tài khoản thanh toán.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Dữ liệu giao dịch: lịch sử mua hàng, sử dụng dịch vụ, khiếu nại.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Dữ liệu mạng xã hội: nếu khách hàng đăng ký tài khoản với mạng xã hội.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>2. Mục đích thu thập và sử dụng dữ liệu:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Đăng ký tài khoản cho khách hàng</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Xác nhận và xác thực khách hàng</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Giao dịch mua bán và cung cấp dịch vụ</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Chăm sóc khách hàng, hỗ trợ kỹ thuật</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Giải quyết khiếu nại và tranh chấp</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Gửi các thông báo về hoạt động của công ty</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Nghiên cứu nhu cầu khách hàng để cải tiến dịch vụ</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Các mục đích khác phù hợp với quy định của pháp luật</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>3. Nguyên tắc xử lý dữ liệu:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Tuân thủ pháp luật về bảo vệ dữ liệu cá nhân</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Tôn trọng quyền riêng tư và lợi ích hợp pháp của khách hàng</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Chỉ thu thập và xử lý dữ liệu với mục đích rõ ràng, hợp pháp</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Đảm bảo dữ liệu chính xác, cập nhật và lưu trữ an toàn</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Không sử dụng dữ liệu sai mục đích, không chia sẻ cho bên thứ 3 ngoài trường hợp có sự</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">đồng ý của khách hàng hoặc theo yêu cầu pháp luật</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Thông báo và được sự đồng ý của khách hàng trước khi thu thập, sử dụng dữ liệu</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">- Xóa bỏ dữ liệu khi hết hiệu lực lưu trữ theo quy định của pháp luật hoặc theo yêu cầu của khách hàng <strong>PRVN </strong>thu thập và sử dụng dữ liệu cá nhân của khách hàng cho mục đích liên lạc, gửi thông báo về các chương trình khuyến mãi và trao quà tặng cho khách hàng.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>Điều 3: Quyền và nghĩa vụ của chủ thể dữ liệu</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>a. Quyền của chủ thể dữ liệu</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">1. Được biết về hoạt động xử lý Dữ liệu cá nhân; đồng ý hoặc không đồng ý cho phép xử lý. Dữ liệu cá nhân theo Cam kết này, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">2. Truy cập để xem, chỉnh sửa hoặc yêu cầu chỉnh sửa Dữ liệu cá nhân, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">3. Rút lại sự đồng ý của mình, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">4. Xóa hoặc yêu cầu xóa Dữ liệu cá nhân theo quy định tại Điều 4 Điều khoản này.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">5. Yêu cầu hạn chế xử lý Dữ liệu cá nhân, trừ trường hợp luật có quy định khác. Việc hạn chế xử lý dữ liệu được thực hiện trong 72 giờ sau khi có yêu cầu của chủ thể dữ liệu, với toàn bộ dữ liệu cá nhân mà chủ thể dữ liệu yêu cầu hạn chế, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">6.Chủ thể dữ liệu cá nhân được yêu cầu cung cấp cho bản thân dữ liệu cá nhân của mình, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">7. Phản đối xử lý dữ liệu nhằm ngăn chặn hoặc hạn chế tiết lộ dữ liệu cá nhân hoặc sử dụng cho mục đích quảng cáo, tiếp thị, trừ trường hợp luật có quy định khác. <strong>PRVN </strong>thực hiện yêu cầu của chủ thể dữ liệu trong 72 giờ sau khi nhận được yêu cầu, trừ trường hợp luật có quy định khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">8. Tự bảo vệ, khiếu nại, tố cáo, khởi kiện, yêu cầu bồi thường thiệt hại theo quy định của pháp luật.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>b. Nghĩa vụ của chủ thể dữ liệu</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">1.Tự bảo vệ dữ liệu cá nhân của mình; yêu cầu các tổ chức, cá nhân khác có liên quan bảo vệ dữ liệu cá nhân của mình.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">2. Tôn trọng, bảo vệ dữ liệu cá nhân của người khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">3. Cung cấp đầy đủ, chính xác dữ liệu cá nhân khi đồng ý cho phép xử lý dữ liệu cá nhân.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">4. Tham gia tuyên truyền, phổ biến kỹ năng bảo vệ dữ liệu cá nhân.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">5.Thực hiện quy định của pháp luật về bảo vệ dữ liệu cá nhân và tham gia phòng, chống các hành vi vi phạm quy định về bảo vệ dữ liệu cá nhân.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>Điều 4: Trả lại và xóa Dữ liệu cá nhân</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">1.Tùy thuộc vào nội dung của Hợp đồng, Bên cung cấp có thể được cung cấp quyền kiểm soát để truy xuất hoặc xóa Dữ liệu cá nhân. Nếu không có yêu cầu xóa Dữ liệu từ Bên cung cấp, việc xóa Dữ liệu cá nhân sẽ diễn ra trong ba mươi (30) ngày sau ngày chấm dứt Hợp đồng hoặc thời gian ngắn hơn theo quy định cụ thể của Hợp đồng. Nếu không có quy định nào tại Hợp đồng về thời gian xóa dữ liệu cá nhân, thì thời gian này sẽ được <strong>PRVN </strong>áp dụng theo quy định nội bộ của <strong>PRVN </strong>từng thời kỳ, và trong mọi trường hợp, Bên cung cấp thừa nhận rằng trước ngày chấm dứt Hợp đồng, Bên cung cấp có trách nhiệm xuất bất kỳ Dữ liệu các nhân nào muốn giữ lại hoặc xóa toàn bộ các dữ liệu cá nhân không cần thiết sau ngày chấm dứt Hợp đồng với điều kiện việc xuất hoặc xóa đó phải tuân thủ theo đúng quy định của pháp luật.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>2. Việc xóa dữ liệu sẽ không áp dụng khi có đề nghị của Bên cung cấp trong các trường hợp:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">a) Pháp luật quy định không cho phép xóa dữ liệu;</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">b) Dữ liệu cá nhân được xử lý bởi cơ quan nhà nước có thẩm quyền với mục đích phục vụ hoạt động của cơ quan nhà nước theo quy định của pháp luật;</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">c) Dữ liệu cá nhân đã được công khai theo quy định của pháp luật;</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">d) Dữ liệu cá nhân được xử lý nhằm phục vụ yêu cầu pháp lý, nghiên cứu khoa học, thống kê theo quy định của pháp luật;</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">đ) Trong trường hợp tình trạng khẩn cấp về quốc phòng, an ninh quốc gia, trật tự an toàn xã hội, thảm họa lớn, dịch bệnh nguy hiểm; khi có nguy cơ đe dọa an ninh, quốc phòng nhưng chưa đến mức ban bố tình trạng khẩn cấp; phòng, chống bạo loạn, khủng bố, phòng, chống tội phạm và vi phạm pháp luật;</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">e) Ứng phó với tình huống khẩn cấp đe dọa đến tính mạng, sức khỏe hoặc sự an toàn của chủ thể dữ liệu hoặc cá nhân khác.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">3. Việc trả lại hoặc xóa Dữ liêu cá nhân theo yêu cầu của Bên cung cấp hoặc khi chấm dứt một phần Hợp đồng sẽ được thực hiện với điều kiện không ảnh hưởng bất lợi đến khả năng cung cấp các dịch vụ còn lại của <strong>PRVN </strong>theo Hợp Đồng và việc trả lại hoặc xóa này không vi phạm các quy định pháp luật về bảo vệ dữ liệu cá nhân và các quy định pháp luật liên quan khác.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>Điều 5: Tuyên bố của Bên cung cấp</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">1.Bên cung cấp tự nguyện đồng ý và hiểu rõ các nội dung quy định tại từng Điều khoản của Cam kết này.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">2. Trường hợp Bên cung cấp là Bên kiểm soát hoặc Bên kiểm soát và xử lý dữ liệu cá nhân, Bên cung cấp đảm bảo:</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">– Chủ thể dữ liệu đã biết rõ và đồng ý toàn bộ với nội dung về việc thông báo xử lý dữ liệu cá nhân được thực hiện một lần trước khi tiến hành đối với hoạt động xử lý dữ liệu cá nhân; và nội dung quy định tại Điều 2 Cam kết này trước khi đồng ý cho Bên cung cấp tiến hành thu thập dữ liệu cá nhân, phù hợp với các quy định tại Cam kết này và Luật bảo vệ dữ liệu.</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">– Đã lập hồ sơ đánh giá tác động của việc xử lý dữ liệu cá nhân đối với Dữ liệu cá nhân</span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">3. Bên cung cấp đảm bảo và bồi thường cho <strong>PRVN </strong>các thiệt hại do Bên cung cấp không thực hiện theo đúng cam kết theo quy định tại Điều này.</span>
</p>
<p>
    &nbsp;
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;"><strong>Điều 6: Điều khoản chung</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#1d1c1d;font-family:Arial,sans-serif;font-size:11.5pt;">Điều khoản này là một phần không thể tách rời với Hợp đồng đã ký giữa Bên cung cấp và <strong>PRVN</strong></span>
</p>
`;
const Home: React.FC = () => {
  /* Hooks */
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');
  /* States */
  const [isOpen, setIsOpen] = useState(false);
  const [policy, setPolicy] = useState(false);
  const [checked, setChecked] = useState(false);

  return (
    <div className="p-home">
      <PageLayout>
        <div className="p-home_content">
          <Container className="p-home_container">
            <Image imgSrc={logo} alt="Chivas Hiball" ratio={343 / 72} />
            <Typography.Text
              extendClasses="p-home_text"
              fontweight="700"
            >
              AGE VERIFICATION
            </Typography.Text>
            <Typography.Text
              extendClasses="p-home_question"
              fontweight="700"
            >
              BẠN TRÊN 18+?
            </Typography.Text>
            <div className="p-home_button">
              <div className="p-home_answer" role="button" onClick={() => setPolicy(true)}>
                <Image imgSrc={btnYes} alt="Yes" ratio={116 / 153} />
              </div>
              <div className="p-home_answer" role="button" onClick={() => setIsOpen(true)}>
                <Image imgSrc={btnNo} alt="No" ratio={116 / 153} />
              </div>
            </div>
          </Container>
          <div className="p-home_footer">
            <Image imgSrc={imgFooter} alt="Chivas Hiball" ratio={371 / 41} />
          </div>
        </div>
      </PageLayout>
      <CustomModal isOpen={isOpen} handleClose={() => setIsOpen(false)}>
        <div className="p-home_reject">
          <Typography.Text extendClasses="p-home_reject_text" fontweight="700">UnderAGE</Typography.Text>
        </div>
      </CustomModal>
      <CustomModal isOpen={policy}>
        <div className="p-home_policy">
          <Image imgSrc={box} alt="Policy" ratio={343 / 563} />
          <div className="p-home_policy_content">
            <Typography.Text extendClasses="p-home_policy_title" fontweight="600">
              đIỀU KHOẢN & ĐIỀU KIỆN
              <br />
              dữ liệu cá nhân
            </Typography.Text>
            <Typography.Text extendClasses="p-home_policy_text">
              <p dangerouslySetInnerHTML={{ __html: contentPolicy || '' }} />
              <div className="p-home_policy_checkbox">
                <Checkbox id="agree" onChange={(e) => setChecked(e.currentTarget.checked)}>
                  <p className="p-home_policy_checkbox-text">
                    Tôi đã xem và đồng ý với
                    <br />
                    <span
                      className="p-home_policy_checkbox-link"
                    >
                      Điều kiện & điều khoản
                    </span>
                    <br />
                    của chương trình.
                  </p>
                </Checkbox>
              </div>
            </Typography.Text>
            <div className="p-home_policy_button">
              <div className="p-home_policy_itemButton">
                <Button
                  variant="primary"
                  onClick={() => setPolicy(false)}
                  extendClasses="p-home_policy-refuse"
                  primaryColor="#fff"
                  hoveredPrimaryColor="#FF2C00"
                >
                  <p className="p-home_policy-textButton">
                    Từ chối
                  </p>
                </Button>
              </div>
              <div className="p-home_policy_itemButton">
                <Button
                  disabled={!checked}
                  variant="primary"
                  onClick={() => navigate(`${ROUTES_PATH.INTRODUCTION}?id=${pubId}`)}
                  primaryColor="#00E151"
                  extendClasses="p-home_policy-next"
                >
                  <p className="p-home_policy-textButton">
                    Tiếp tục
                  </p>
                </Button>
              </div>
            </div>
          </div>
        </div>
      </CustomModal>
    </div>
  );
};

export default Home;
