// eslint-disable-next-line import/no-extraneous-dependencies
import { QRCodeSVG } from 'qrcode.react';
import React from 'react';
import { useSearchParams } from 'react-router-dom';
import { Typography, Image } from 'tfc-components';

import logo from 'assets/images/logo1.png';
import PageLayout from 'components/organisms/PageLayout';

const Qrcode: React.FC = () => {
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');
  return (
    <PageLayout>
      <div className="p-qrCode">
        <Image imgSrc={logo} alt="Chivas Hiball" ratio={343 / 72} />
        <Typography.Text extendClasses="p-qrCode_title" fontweight="700">
          Vui lòng sử dụng
          <br />
          thiết bị di động quét mã QR
          <br />
          để tham gia chương trình.
        </Typography.Text>
        <QRCodeSVG
          className="p-qrCode_qr"
          size={300}
          value={`${window.location.origin}?id=${pubId}`}
        />
      </div>
    </PageLayout>
  );
};

export default Qrcode;
