import { AxiosResponse } from 'axios';

import { ISystemData } from './types';

import axiosInstance from 'services/common/instance';

const getSystemDataService = async (): Promise<ISystemData> => {
  const response = await axiosInstance.get<AxiosResponse<ISystemData>>('systems/general');
  return response.data.data;
};

export default getSystemDataService;
