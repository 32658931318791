import React from 'react';
import { Loading } from 'tfc-components';

import useWindowDimensions from 'hooks/useWindowDemensions';
import { COLORS } from 'utils/constants';

interface PageLayoutProps {
  loading?: boolean;
  children?: React.ReactNode;
}

const PageLayout: React.FC<PageLayoutProps> = ({
  loading, children
}) => {
  const { height } = useWindowDimensions();

  return (
    <div className="o-pageLayout" style={{ height: `${height}px` }}>
      <div className="o-pageLayout_content">
        {children}
      </div>
      {loading && (
        <div className="o-pageLayout_loading">
          <Loading.CircleDashed extendClasses="o-pageLayout_loading_indicator" color={COLORS.white} width={48} />
        </div>
      )}
    </div>
  );
};

export default PageLayout;
