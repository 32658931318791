/* eslint-disable @typescript-eslint/no-unused-vars */
import React, { useEffect, useMemo } from 'react';
import { Container } from 'react-bootstrap';
import { useQuery } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Image, Tabs, Typography } from 'tfc-components';

import logo from 'assets/images/logo2.png';
import box from 'assets/images/profile/box.png';
import store from 'assets/images/profile/store.png';
import PageLayout from 'components/organisms/PageLayout';
import { getPubService } from 'services/pub';
import { useAppSelector } from 'store/hooks';
import { ROUTES_PATH } from 'utils/constants';

const Profile: React.FC = () => {
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');
  const general = useAppSelector((state) => state.system.data);
  const profile = useAppSelector((state) => state.user.profile);
  const maxPoint = useMemo(() => {
    if (!profile) {
      return 0;
    }
    switch (profile?.latestCheckin) {
      case 'northern':
        return (general?.others.hipoint || 0) * (general?.others.checkinNorthernRegion || 0);
      case 'central':
        return (general?.others.hipoint || 0) * (general?.others.checkinCentralRegion || 0);
      case 'southern':
        return (general?.others.hipoint || 0) * (general?.others.checkinSouthernRegion || 0);
      default:
        return 0;
    }
  }, [profile, general]);
  const totalPoint = useMemo(() => {
    if (!profile) {
      return 0;
    }
    switch (profile?.latestCheckin) {
      case 'northern':
        return (general?.others.hipoint || 0) * (general?.others.pubNorthernCount || 0);
      case 'central':
        return (general?.others.hipoint || 0) * (general?.others.pubCentralCount || 0);
      case 'southern':
        return (general?.others.hipoint || 0) * (general?.others.pubSouthernCount || 0);
      default:
        return 0;
    }
  }, [profile, general]);
  useEffect(() => {
    if (!profile || !maxPoint) {
      return;
    }
    if (profile?.hipoint >= maxPoint) {
      navigate(`${ROUTES_PATH.CONGRATULATION}?id=${pubId}`);
    }
  }, [maxPoint, navigate, profile, pubId]);

  const { data: pubResponse } = useQuery(['pub'], getPubService);

  return (
    <PageLayout>
      <div className="p-profile">
        <Container>
          <Image imgSrc={logo} alt="Chivas" ratio={343 / 28} />
          <div className="p-profile_point">
            <Typography.Text extendClasses="p-profile_point_content" fontweight="900">
              Hipoint của bạn:
              {' '}
              {profile?.hipoint}
            </Typography.Text>
          </div>
          <Typography.Text extendClasses="p-profile_point_text" fontweight="700">
            {profile?.hipoint}
            /
            {totalPoint}
            {' '}
            hipoints
          </Typography.Text>
          <Typography.Text
            extendClasses="p-profile_point_text p-profile_point_notice"
            fontweight="700"
          >
            bẠN CẦN TÍCH LŨY
            <br />
            ĐỦ
            {' '}
            {maxPoint}
            {' '}
            HIPOINTS
            <br />
            ĐỂ NHẬN THƯỞNG
          </Typography.Text>
          <div className="p-profile_box">
            <Image imgSrc={box} alt="Chivas" ratio={303 / 320} />
            <Typography.Text extendClasses="p-profile_box_text" fontweight="600">
              Drink Responsibly
            </Typography.Text>
          </div>
          <div className="p-profile_list">
            <div className="p-profile_list_content">
              <Typography.Text extendClasses="p-profile_list_title" fontweight="700">
                DANH SÁCH CÁC QUÁN
              </Typography.Text>
              <Typography.Text extendClasses="p-profile_list_desc" fontweight="700">
                ĐỂ BẠN TÍCH LŨY HiPoint
              </Typography.Text>
              <div className="p-profile_list_tabs">
                <Tabs value="northern" extendClasses="p-profile_list_tabs_main">
                  <Tabs.List>
                    <Tabs.Item
                      value="northern"
                      extendClasses="p-profile_list_tabs_item"
                      activeClass="p-profile_list_tabs_active"
                    >
                      Miền Bắc
                    </Tabs.Item>
                    <Tabs.Item
                      value="southern"
                      extendClasses="p-profile_list_tabs_item"
                      activeClass="p-profile_list_tabs_active"
                    >
                      Miền Nam
                    </Tabs.Item>
                    <Tabs.Item
                      value="central"
                      extendClasses="p-profile_list_tabs_item"
                      activeClass="p-profile_list_tabs_active"
                    >
                      Miền Trung
                    </Tabs.Item>
                  </Tabs.List>
                  <Tabs.Content value="northern" extendClasses="p-profile_list_tabs_content">
                    {pubResponse?.['northern' as keyof RegionType] ? (
                      pubResponse?.['northern' as keyof RegionType]?.map((item, index) => (
                        <div className="p-profile_store" key={`northern-pub-${index.toString()}`}>
                          <div className="p-profile_store_image">
                            <Image
                              imgSrc={item?.image?.path ? item?.image?.path : store}
                              alt="Chivas"
                              ratio={1}
                            />
                          </div>
                          <div className="p-profile_store_address">
                            <strong>{item.name}</strong>
                            <br />
                            {item?.address}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Typography.Text
                        extendClasses="p-profile_store_noData"
                        fontweight="600"
                        textStyle="center"
                      >
                        No Data
                      </Typography.Text>
                    )}
                  </Tabs.Content>
                  <Tabs.Content value="southern" extendClasses="p-profile_list_tabs_content">
                    {pubResponse?.['southern' as keyof RegionType] ? (
                      pubResponse?.['southern' as keyof RegionType]?.map((item, index) => (
                        <div className="p-profile_store" key={`southern-pub-${index.toString()}`}>
                          <div className="p-profile_store_image">
                            <Image
                              imgSrc={item?.image?.path ? item?.image?.path : store}
                              alt="Chivas"
                              ratio={1}
                            />
                          </div>
                          <div className="p-profile_store_address">
                            <strong>{item.name}</strong>
                            <br />
                            {item?.address}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Typography.Text
                        extendClasses="p-profile_store_noData"
                        fontweight="600"
                        textStyle="center"
                      >
                        No Data
                      </Typography.Text>
                    )}
                  </Tabs.Content>
                  <Tabs.Content value="central" extendClasses="p-profile_list_tabs_content">
                    {pubResponse?.['central' as keyof RegionType] ? (
                      pubResponse?.['central' as keyof RegionType]?.map((item, index) => (
                        <div className="p-profile_store" key={`central-pub-${index.toString()}`}>
                          <div className="p-profile_store_image">
                            <Image
                              imgSrc={item?.image?.path ? item?.image?.path : store}
                              alt="Chivas"
                              ratio={1}
                            />
                          </div>
                          <div className="p-profile_store_address">
                            <strong>{item.name}</strong>
                            <br />
                            {item?.address}
                          </div>
                        </div>
                      ))
                    ) : (
                      <Typography.Text
                        extendClasses="p-profile_store_noData"
                        fontweight="600"
                        textStyle="center"
                      >
                        No Data
                      </Typography.Text>
                    )}
                  </Tabs.Content>
                </Tabs>
              </div>
              <Typography.Text extendClasses="p-profile_list_desc" fontweight="700">
                Cùng CR HiBALL
                <br />
                đón chờ những hoạt động
                <br />
                cực kì thú vị sẽ/sắp diễn ra
                <br />
                tại những trendy cocktail bars
                <br />
                trong thời gian tới
              </Typography.Text>
            </div>
          </div>
        </Container>
      </div>
    </PageLayout>
  );
};

export default Profile;
