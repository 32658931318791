import React, { useEffect } from 'react';
import Modal from 'react-modal';

import mapModifiers from 'utils/functions';

export type VariantModal = 'default';

interface ModalProps {
  isOpen: boolean;
  handleClose?: () => void;
  variant?: VariantModal;
  children?: React.ReactNode;
  footer?: boolean;
  modifiers?: string[];
}

const CustomModal: React.FC<ModalProps> = ({
  isOpen,
  handleClose,
  variant,
  children,
  footer,
  modifiers,
}) => {
  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = 'hidden';
      document.body.style.height = '100vh';
    } else {
      document.body.style.overflow = 'auto';
      document.body.style.height = 'auto';
    }
  }, [isOpen]);

  return (
    <Modal
      isOpen={isOpen}
      onRequestClose={handleClose}
      closeTimeoutMS={250}
      className={mapModifiers('o-modal', variant)}
      ariaHideApp={false}
      portalClassName={mapModifiers('o-modal_portal', isOpen && 'open')}
      overlayClassName={mapModifiers('o-modal_overlay', variant)}
    >
      <div className="o-modal_main">
        <div className={mapModifiers('o-modal_wrapper', modifiers)}>
          <div className="o-modal_body">
            {children}
          </div>
          {footer && (
            <div className="o-modal_footer">
              <button type="button" className="o-modal_close" onClick={handleClose}>
                Đóng
              </button>
            </div>
          )}
        </div>
      </div>
    </Modal>
  );
};

CustomModal.defaultProps = {
  children: undefined,
};

export default CustomModal;
