import moment from 'moment';
import * as yup from 'yup';

export const phoneRegExp = /^0[1-9]\d{8}$/;

const useSchemas = () => {
  const customerInfo = yup.object().shape({
    name: yup.string().required('Vui lòng nhập tên'),
    phone: yup.string().required('Vui lòng cung cấp thông tin').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    dob: yup.string().required('Vui lòng nhập ngày sinh').test('dob', 'Tuổi phải trên 18', (value) => moment().diff(moment(value), 'years') >= 18),
  });
  const prizeExchange = yup.object().shape({
    receiverPhone: yup.string().required('Vui lòng nhập số điện thoại').matches(phoneRegExp, 'Số điện thoại chưa hợp lệ'),
    receiverName: yup.string().required('Vui lòng nhập tên'),
    receiverAddress: yup.string().required('Vui lòng nhập địa chỉ'),
  });

  return {
    customerInfo,
    prizeExchange
  };
};

export default useSchemas;
