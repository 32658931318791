import { AxiosResponse } from 'axios';

import {
  ICheckIn, ICustomer, IPrizeExchangeInfo, IProfile, IRegisterCustomer
} from './types';

import axiosInstance from 'services/common/instance';

export const checkinService = async (data: ICheckIn): Promise<ICustomer> => {
  const response = await axiosInstance.post<AxiosResponse<ICustomer>>('customers/checks', data);
  return response.data.data;
};

export const registerService = async (data: IRegisterCustomer): Promise<ICustomer> => {
  const response = await axiosInstance.post<AxiosResponse<ICustomer>>('customers', data);
  return response.data.data;
};

export const prizeExChangeInfoService = async (data: IPrizeExchangeInfo): Promise<void> => {
  await axiosInstance.post<AxiosResponse<ICustomer>>(`customers/prize-exchanges/${data.phone}`, {
    receiverPhone: data.receiverPhone,
    receiverName: data.receiverName,
    receiverAddress: data.receiverAddress
  });
};

export const getProfileService = async (phone: string): Promise<IProfile> => {
  const response = await axiosInstance.get<AxiosResponse<IProfile>>(`customers/profiles/${phone}`);
  return response.data.data;
};
