import {
  IPub, IValidCheckIn
} from './types';

import axiosInstance from 'services/common/instance';

export const getPubService = async (): Promise<IPub> => {
  const response = await axiosInstance.get('pubs');
  return response.data.data;
};

export const checkinPubService = async (data: IValidCheckIn): Promise<void> => {
  await axiosInstance.post('customers/checkin-pub', data);
};
