import React from 'react';

import mapModifiers from 'utils/functions';

export const iconList = {
  calendar: 'calendar',
};

export type IconName = keyof typeof iconList;

export type IconSize = '14' | '18' | '24' | '80';
interface IconProps {
  iconName: IconName;
  size?: IconSize;
}

const Icon: React.FC<IconProps> = ({ iconName, size }) => (
  <i className={mapModifiers('a-icon', iconName, size)} />
);

Icon.defaultProps = {
  size: undefined,
};

export default Icon;
