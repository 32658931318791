import './App.scss';
import { FpjsProvider } from '@fingerprintjs/fingerprintjs-pro-react';
import React from 'react';
import { QueryClient, QueryClientProvider } from 'react-query';
import { Provider } from 'react-redux';
import { BrowserRouter, Route, Routes } from 'react-router-dom';

import Mainlayout from 'components/templates/Mainlayout';
import useDidMount from 'hooks/useDidMount';
import Congratulation from 'pages/Congratulation';
import GetLocation from 'pages/GetLocation';
import Home from 'pages/Home';
import Introduction from 'pages/Introduction';
import Profile from 'pages/Profile';
import Qrcode from 'pages/Qrcode';
import Registration from 'pages/Registration';
import { store } from 'store';
import { useAppDispatch } from 'store/hooks';
import { getSystemDataAction } from 'store/system';
import { ROUTES_PATH } from 'utils/constants';

const App: React.FC = () => {
  const dispatch = useAppDispatch();
  useDidMount(() => {
    dispatch(getSystemDataAction());
  });
  return (
    <Routes>
      <Route element={<Mainlayout />}>
        <Route path={ROUTES_PATH.HOME} element={<Home />} />
        <Route path={ROUTES_PATH.INTRODUCTION} element={<Introduction />} />
        <Route path={ROUTES_PATH.REGISTRATION} element={<Registration />} />
        <Route path={ROUTES_PATH.PROFILE} element={<Profile />} />
        <Route path={ROUTES_PATH.CONGRATULATION} element={<Congratulation />} />
        <Route path={ROUTES_PATH.QRCODE} element={<Qrcode />} />
      </Route>
      <Route path="/get-location" element={<GetLocation />} />
    </Routes>
  );
};

const AppWrapper: React.FC = () => {
  const queryClient = new QueryClient({
    defaultOptions: {
      queries: {
        retry: 0,
        refetchOnMount: true,
        refetchOnWindowFocus: false,
        refetchOnReconnect: false,
      },
    },
  });
  return (
    <Provider store={store}>
      <QueryClientProvider client={queryClient}>
        <FpjsProvider
          loadOptions={{
            apiKey: 'wJwSwHJO9z1ME7Vxi4Mp',
            region: 'ap',
            scriptUrlPattern: '/loader.js',
          }}
        >
          <BrowserRouter>
            <App />
          </BrowserRouter>
        </FpjsProvider>
      </QueryClientProvider>
    </Provider>
  );
};

export default AppWrapper;
