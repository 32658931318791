import React from 'react';
import { Container } from 'react-bootstrap';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Image, Typography } from 'tfc-components';

import arrowDown from 'assets/images/arrow_down.svg';
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import glass from 'assets/images/chivas_glass.png';
import products from 'assets/images/intro_prod.png';
import logo from 'assets/images/logo1.png';
import machine from 'assets/images/machine.png';
import apple from 'assets/images/products/apple.png';
import chivas from 'assets/images/products/chivas-regal.png';
import ice from 'assets/images/products/ice.png';
import soda from 'assets/images/products/soda.png';
import Animate from 'components/organisms/Animate';
import PageLayout from 'components/organisms/PageLayout';
import { ROUTES_PATH } from 'utils/constants';

const Introduction: React.FC = () => {
  const navigation = useNavigate();
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');
  return (
    <PageLayout>
      <div className="p-introduction">
        <Container>
          <Image imgSrc={logo} alt="Chivas Hiball" ratio={343 / 72} />
          <Typography.Text extendClasses="p-introduction_text" fontweight="500">
            Bạn biết gì về HiBALL?
            <br />
            <br />
            Thức uống phổ biến nhất Nhật Bản
            <br />
            của nhà Chivas
          </Typography.Text>
          <div className="p-introduction_product">
            <Image imgSrc={products} alt="products" ratio={343 / 322} />
            <div className="p-introduction_product_list">
              <Animate type="fadeInDown" noScroll extendClassName="list-animate">
                <Image extendClasses="p-introduction_product_image" imgSrc={chivas} alt="Chivas" ratio={52 / 100} />
              </Animate>
              <Animate type="fadeInDown" noScroll extendClassName="list-animate">
                <Image extendClasses="p-introduction_product_image" imgSrc={soda} alt="Soda" ratio={52 / 61} />
              </Animate>
              <Animate type="fadeInDown" noScroll extendClassName="list-animate">
                <Image extendClasses="p-introduction_product_image" imgSrc={ice} alt="Ice" ratio={63 / 61} />
              </Animate>
              <Animate type="fadeInDown" noScroll extendClassName="list-animate">
                <Image extendClasses="p-introduction_product_image" imgSrc={apple} alt="Apple" ratio={96 / 61} />
              </Animate>
            </div>
            <div className="p-introduction_product_bottom">
              <div className="p-introduction_product_glass">
                <Animate type="fadeInUp" noScroll>
                  <Typography.Text extendClasses="p-introduction_product_bottom_text" fontweight="900">
                    =
                  </Typography.Text>
                  <Image imgSrc={glass} alt="Glass" ratio={85 / 202} />
                  <Typography.Text extendClasses="p-introduction_product_text" fontweight="600">
                    Drink Responsibly
                  </Typography.Text>
                </Animate>
              </div>
            </div>
          </div>
          <Animate type="slideInUp" noScroll extendClassName="delay-2s">
            <div className="p-introduction_machine">
              <Image imgSrc={machine} alt="Machine" ratio={305 / 481} />
            </div>
            <div className="p-introduction_continue">
              <Typography.Text extendClasses="p-introduction_continue_text" fontweight="900">
                THAM GIA VÀ RINH NGAY
                <br />
                QUÀ CỰC CHẤT
              </Typography.Text>
              <div className="p-introduction_continue_icon" role="button" onClick={() => navigation(`${ROUTES_PATH.REGISTRATION}?id=${pubId}`)}>
                <Image imgSrc={arrowDown} alt="Arrow" ratio={5 / 7} />
              </div>
            </div>
          </Animate>
        </Container>
      </div>
    </PageLayout>
  );
};

export default Introduction;
