export const COLORS = {
  white: '#ffffff',
};

export const LOCALSTORAGE_KEYS = {
  CHIVAS_HIBALL_USER: 'CHIVAS_HIBALL_USER',
};

export const ROUTES_PATH = {
  HOME: '/',
  INTRODUCTION: '/introduction',
  REGISTRATION: '/registration',
  PROFILE: '/profile',
  CONGRATULATION: '/congratulation',
  QRCODE: '/qr-code',
};
