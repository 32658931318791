import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';

import getSystemDataService from 'services/system';
import { ISystemData } from 'services/system/types';

interface SystemState {
  data?: ISystemData;
  locationModal?: boolean;
}

const initialState: SystemState = {
};

export const getSystemDataAction = createAsyncThunk<
  ISystemData,
  void,
  { rejectValue: any }
>('systemReducer/getSystemDataAction', async (_, { rejectWithValue }) => {
  try {
    const res = await getSystemDataService();
    return res;
  } catch (error) {
    return rejectWithValue(error as any);
  }
});

export const systemSlice = createSlice({
  name: 'systemReducer',
  initialState,
  reducers: {
    openLocationModal: ($state) => {
      $state.locationModal = true;
    },
    closeLocationModal: ($state) => {
      $state.locationModal = false;
    },
  },
  extraReducers(builder) {
    builder.addCase(getSystemDataAction.fulfilled, ($state, action) => {
      $state.data = action.payload;
    });
  },
});

export const { openLocationModal, closeLocationModal } = systemSlice.actions;

export default systemSlice.reducer;
