import { yupResolver } from '@hookform/resolvers/yup';
import React, { useEffect, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { Image, Input, Typography } from 'tfc-components';

import box from 'assets/images/congratulation/box.png';
import logo from 'assets/images/logo2.png';
import send from 'assets/images/registration/send.png';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import returnValidateErrorMessages from 'services/common/errors';
import { prizeExChangeInfoService } from 'services/customer';
import { useAppSelector } from 'store/hooks';
import { LOCALSTORAGE_KEYS } from 'utils/constants';
import useSchemas from 'utils/schemas';

export interface PrizeExchangeFormType {
  receiverPhone: string
  receiverName: string
  receiverAddress: string
}
const fieldToText = (field: string) => {
  switch (field) {
    case 'receiverName':
      return 'Tên';
    case 'receiverPhone':
      return 'Số điện thoại';
    case 'receiverAddress':
      return 'Địa chỉ';
    default:
      return '';
  }
};
const Congratulation: React.FC = () => {
  const { prizeExchange } = useSchemas();
  const receivedGift = useAppSelector((state) => state.user.profile?.receivedGift);
  const method = useForm<PrizeExchangeFormType>({
    mode: 'onChange',
    resolver: yupResolver(prizeExchange),
    defaultValues: {
      receiverPhone: '',
      receiverName: '',
      receiverAddress: '',
    },
  });

  /* States */
  const [globalErr, setGlobalErr] = React.useState<string | undefined>();
  const [received, setReceived] = useState(false);

  /* Queries */
  const { mutate: prizeEchangeMutate, isLoading } = useMutation('prizeExchange', prizeExChangeInfoService, {
    onSuccess() {
      setReceived(true);
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          if (err.field === 'name' || err.field === 'phone' || err.field === 'dob') {
            method.setError(
              err.field,
              { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
            );
          } else {
            setGlobalErr('Đã có lỗi xảy ra!');
          }
        });
      } else {
        setGlobalErr(`Đã có lỗi(${error.response.status}) xảy ra!`);
      }
    },
  });
  const onSubmit = (data: PrizeExchangeFormType) => {
    const userSaved = localStorage.getItem(LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER);
    if (!userSaved) {
      return;
    }
    const userParser = JSON.parse(userSaved) as UserSavedLocal;
    prizeEchangeMutate({ phone: userParser.phone, ...data });
  };

  useEffect(() => {
    setReceived(receivedGift || false);
  }, [receivedGift]);
  return (
    <PageLayout loading={isLoading}>
      <div className="p-congratulation">
        <Container>
          <Image imgSrc={logo} alt="Chivas" ratio={343 / 28} />
          <Typography.Text extendClasses="p-congratulation_text" fontweight="700">
            CHÚC MỪNG BẠN ĐÃ
            <br />
            TÍCH LŨY ĐỦ HIPOINT
            <br />
            VÀ NHẬN QUÀ TẶNG ĐẶC BIỆT
          </Typography.Text>
          <div className="p-congratulation_box">
            <Image imgSrc={box} alt="Chivas" ratio={217 / 209} />
          </div>
          {!received && (
            <div className="p-congratulation_form">
              <div className="p-congratulation_form_wrapper">
                <div className="p-congratulation_form_content">
                  <div className="p-congratulation_form_title">
                    <Typography.Text fontweight="700">congratulation</Typography.Text>
                  </div>
                  <div className="p-congratulation_form_name">
                    <Typography.Text fontweight="700">chivas hiball winner</Typography.Text>
                  </div>
                  <div className="p-congratulation_form_desc">
                    <Typography.Text fontweight="500">
                      Hãy điền thông tin nhận hàng để
                      Chivas gửi đến bạn phần quà đặt biệt
                    </Typography.Text>
                  </div>
                  <FormProvider {...method}>
                    <div className="p-congratulation_form_field">
                      <Typography.Text extendClasses="p-congratulation_form_label" fontweight="600">
                        Địa chỉ:
                      </Typography.Text>
                      <Controller
                        name="receiverAddress"
                        control={method.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="p-congratulation_form_item">
                            <Input
                              {...field}
                              name="receiverAddress"
                              extendClasses="p-congratulation_form_input"
                              onChange={field.onChange}
                            />
                            {error && (
                              <div className="form-error-message">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className="p-congratulation_form_field">
                      <Typography.Text extendClasses="p-congratulation_form_label" fontweight="600">
                        Người nhận:
                      </Typography.Text>
                      <Controller
                        name="receiverName"
                        control={method.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="p-congratulation_form_item">
                            <Input
                              {...field}
                              name="receiverName"
                              extendClasses="p-congratulation_form_input"
                              onChange={field.onChange}
                            />
                            {error && (
                              <div className="form-error-message">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                    <div className="p-congratulation_form_field">
                      <Typography.Text extendClasses="p-congratulation_form_label" fontweight="600">
                        SĐT:
                      </Typography.Text>
                      <Controller
                        name="receiverPhone"
                        control={method.control}
                        render={({ field, fieldState: { error } }) => (
                          <div className="p-congratulation_form_item">
                            <Input
                              {...field}
                              name="receiverPhone"
                              extendClasses="p-congratulation_form_input"
                              onChange={field.onChange}
                            />
                            {error && (
                              <div className="form-error-message">{error.message}</div>
                            )}
                          </div>
                        )}
                      />
                    </div>
                  </FormProvider>
                </div>
              </div>
              <div className="p-congratulation_form_btn" role="button" onClick={method.handleSubmit(onSubmit)}>
                <Image imgSrc={send} alt="Chivas" ratio={196 / 84} />
              </div>
            </div>
          )}
        </Container>
      </div>
      <CustomModal isOpen={!!globalErr}>
        <div className="popup-notice error">
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">Lỗi</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            {globalErr}
          </Typography.Text>
          <div
            className="popup-notice_confirm"
            role="button"
            onClick={() => setGlobalErr(undefined)}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐỒNG Ý</Typography.Text>
          </div>
        </div>
      </CustomModal>
    </PageLayout>
  );
};

export default Congratulation;
