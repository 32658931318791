/* eslint-disable react/no-danger */
import { useVisitorData } from '@fingerprintjs/fingerprintjs-pro-react';
import { yupResolver } from '@hookform/resolvers/yup';
import React, { useCallback, useState } from 'react';
import { Container } from 'react-bootstrap';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useMutation } from 'react-query';
import { useNavigate, useSearchParams } from 'react-router-dom';
import {
  Button, Image, Input, Typography
} from 'tfc-components';

import boxModal from 'assets/images/box-modal-l.png';
import button from 'assets/images/btn-registration.png';
import imgFooter from 'assets/images/chivas-white.png';
import box from 'assets/images/chivas_box.png';
import homekit from 'assets/images/homekit.png';
import logo from 'assets/images/logo2.png';
import combo from 'assets/images/registration/combo.png';
import send from 'assets/images/registration/send.png';
import title from 'assets/images/registration/title_form.png';
import DatePickerCustom from 'components/molecules/DatePicker';
import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import returnValidateErrorMessages, { mappingErrorLocationGetter } from 'services/common/errors';
import { registerService } from 'services/customer';
import { useAppDispatch, useAppSelector } from 'store/hooks';
import { openLocationModal } from 'store/system';
import { saveProfile } from 'store/user';
import { LOCALSTORAGE_KEYS, ROUTES_PATH } from 'utils/constants';
import mapModifiers, { formatDateYYYYMMDD, getLocation } from 'utils/functions';
import useSchemas from 'utils/schemas';

const contentRegistration = `
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;"><strong>1. Cách tham dự:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;">Bạn đến các địa điểm đang diễn ra chương trình (theo danh sách), scan mã QR check-in tích điểm đổi quà tại quán.</span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;"><strong>2. Cách tính điểm:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;">1 lượt check-in/quán = 5 điểm</span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;"><strong>Lưu ý:</strong> Bạn chỉ được check-in duy nhất 1 lượt/quán </span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;"><strong>3. Điều kiện nhận thưởng:</strong></span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;">Bạn cần tích đủ 15 điểm (tương đương với 3/4 số quán) ở khu vực miền Bắc hoặc miền Nam để đủ điều kiện đổi quà.</span>
</p>
<p>
    <span style="background-color:transparent;color:#000000;font-family:Arial,sans-serif;font-size:11pt;">Bạn cần tích đủ 10 điểm (tương đương với 2/3 số quán) ở khu vực miền Trung để đủ điều kiện đổi quà</span>
</p>
`;
export interface CustomerInfoFormTypes {
  name: string;
  phone: string;
  dob: string;
}

const getEnoughtAge = () => {
  const date = new Date();
  return `${date.getFullYear() - 18}-${date.getMonth() + 1}-${date.getDate()}`;
};

const fieldToText = (field: string) => {
  switch (field) {
    case 'name':
      return 'Tên';
    case 'phone':
      return 'Số điện thoại';
    case 'dob':
      return 'Ngày sinh';
    default:
      return '';
  }
};

const Registration: React.FC = () => {
  /* Hooks */
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const pubId = searchParams.get('id');
  const general = useAppSelector((state) => state.system.data);
  const { customerInfo } = useSchemas();
  const method = useForm<CustomerInfoFormTypes>({
    mode: 'onChange',
    resolver: yupResolver(customerInfo),
    defaultValues: {
      name: '',
      phone: '',
      dob: new Date(getEnoughtAge()).toISOString(),
    },
  });

  const {
    getData
  } = useVisitorData({ tag: 'subscription-form' }, { immediate: false });

  /* States */
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [condition, setCondition] = useState(false);
  const [locationNotice, setLocationNotice] = useState({
    trigger: false,
    error: 'PERMISSION_DENIED' as LocationErrorType
  });
  const [globalErr, setGlobalErr] = useState<string | undefined>();
  const [exist, setExist] = useState<boolean>(false);
  const maxPoint = useCallback((region?: RegionType) => {
    switch (region) {
      case 'northern':
        return (general?.others.hipoint || 0) * (general?.others.checkinNorthernRegion || 0);
      case 'central':
        return (general?.others.hipoint || 0) * (general?.others.checkinCentralRegion || 0);
      case 'southern':
        return (general?.others.hipoint || 0) * (general?.others.checkinSouthernRegion || 0);
      default:
        return 0;
    }
  }, [general]);
  /* Queries */
  const { mutate: registerMutate, isLoading } = useMutation('registerCustomer', registerService, {
    onSuccess(data) {
      dispatch(saveProfile(data.customerData));
      navigate(`${ROUTES_PATH.PROFILE}?id=${pubId}`);
    },
    onError(error: any) {
      if (error.length > 0) {
        error.forEach((err: ValidateError) => {
          if (err.field === 'name' || err.field === 'phone' || err.field === 'dob') {
            method.setError(
              err.field,
              { message: returnValidateErrorMessages(err, fieldToText(err.field)) }
            );
          } else {
            switch (err.code) {
              case '9524':
                setGlobalErr('Vị trí Checkin tại quán<br />không hợp lệ.');
                break;
              case '9525':
                setExist(true);
                setGlobalErr(`Vui lòng quét mã QR<br />ở những quán khác để tích luỹ<br />đủ ${maxPoint(err.detail as RegionType | undefined)} HIPOINTS<br />và nhận thưởng.`);
                break;
              case '9526':
                setGlobalErr(`Thiết bị này đã được đăng ký<br />tham gia với số điện thoại<br />${err.detail} rồi`);
                break;
              case '9521':
                setGlobalErr('Số điện thoại này đã được<br />tham gia bởi thiết bị khác.');
                break;
              case '9527':
                navigate(`${ROUTES_PATH.QRCODE}?id=${pubId}`);
                break;
              default:
                setGlobalErr('Đã có lỗi xảy ra!');
                break;
            }
          }
        });
      } else {
        setGlobalErr(`Đã có lỗi(${error.response.status}) xảy ra!`);
      }
    },
  });

  /* Functions */
  const onSubmit = async (data: CustomerInfoFormTypes) => {
    try {
      setLoading(true);
      const location = await getLocation();
      const userSaved = localStorage.getItem(LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER);
      if (!userSaved) {
        getData().then((res) => {
          localStorage.setItem(
            LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER,
            JSON.stringify({ phone: data.phone, deviceId: res.visitorId })
          );
          registerMutate({
            ...data,
            pubId: Number(pubId),
            dob: formatDateYYYYMMDD(data.dob),
            lat: location.lat,
            long: location.long,
            deviceId: res.visitorId,
            scanDeviceId: true
          });
        }).catch((error) => {
          setGlobalErr(error.message);
        });
      } else {
        const parser = JSON.parse(userSaved) as UserSavedLocal;
        if (data.phone !== parser.phone) {
          localStorage.setItem(
            LOCALSTORAGE_KEYS.CHIVAS_HIBALL_USER,
            JSON.stringify({ phone: data.phone, deviceId: parser.deviceId })
          );
        }
        registerMutate({
          ...data,
          pubId: Number(pubId),
          dob: formatDateYYYYMMDD(data.dob),
          lat: location.lat,
          long: location.long,
          deviceId: parser.deviceId,
          scanDeviceId: false
        });
      }
    } catch (error: any) {
      setLocationNotice({ trigger: true, error: mappingErrorLocationGetter(error.code) });
    } finally {
      setLoading(false);
    }
  };

  return (
    <PageLayout loading={isLoading || loading}>
      <div className="p-registration">
        <Container>
          <Image imgSrc={logo} alt="Chivas" ratio={343 / 28} />
          <div className={mapModifiers('p-registration_intro', show && 'hidden')}>
            <Typography.Text extendClasses="p-registration_title" fontweight="700">
              quét qr, đăng nhập
              <br />
              để tích lũy đủ hipoint
              {' '}
              <br />
              và nhận quà tặng đặc biệt
            </Typography.Text>
            <Image imgSrc={homekit} alt="Chivas" ratio={339 / 78} />
            <Image extendClasses="p-registration_box" imgSrc={box} alt="Chivas" ratio={311 / 298} />
            <div onClick={() => setCondition(true)}>
              <Typography.Text extendClasses="p-registration_condition" fontweight="600">
                Điều kiện nhận thưởng
              </Typography.Text>
            </div>
            <div className="p-registration_button" role="button" onClick={() => setShow(true)}>
              <Image imgSrc={button} alt="Chivas" ratio={199 / 84} />
            </div>
          </div>
          <div className={mapModifiers('p-registration_form', show && 'show')}>
            <div className="p-registration_form_wrapper">
              <div className="p-registration_form_content">
                <Image imgSrc={title} alt="Chivas" ratio={255 / 70} />
                <FormProvider {...method}>
                  <div className="p-registration_form_field first">
                    <Typography.Text extendClasses="p-registration_form_label" fontweight="600">
                      Tên:
                    </Typography.Text>
                    <Controller
                      name="name"
                      control={method.control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="p-registration_form_item">
                          <Input
                            {...field}
                            name="name"
                            extendClasses="p-registration_form_input"
                            onChange={field.onChange}
                          />
                          {error && (
                            <div className="form-error-message">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="p-registration_form_field">
                    <Typography.Text extendClasses="p-registration_form_label" fontweight="600">
                      SĐT:
                    </Typography.Text>
                    <Controller
                      name="phone"
                      control={method.control}
                      render={({ field, fieldState: { error } }) => (
                        <div className="p-registration_form_item">
                          <Input
                            {...field}
                            name="phone"
                            extendClasses="p-registration_form_input"
                            onChange={field.onChange}
                          />
                          {error && (
                            <div className="form-error-message">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                  <div className="p-registration_form_field">
                    <Typography.Text extendClasses="p-registration_form_label" fontweight="600">
                      Ngày sinh:
                    </Typography.Text>
                    <Controller
                      control={method.control}
                      name="dob"
                      render={({ field: { onChange, value }, fieldState: { error } }) => (
                        <div className="p-registration_form_item">
                          <DatePickerCustom
                            maxDate={new Date()}
                            handleSelectDate={onChange}
                            defaultDate={new Date(value)}
                          />
                          {error && (
                            <div className="form-error-message">{error.message}</div>
                          )}
                        </div>
                      )}
                    />
                  </div>
                </FormProvider>
              </div>
            </div>
            <div className="p-registration_form_btn" role="button" onClick={method.handleSubmit(onSubmit)}>
              <Image imgSrc={send} alt="Chivas" ratio={196 / 84} />
            </div>

            <div className="p-registration_form_combo">
              <Image imgSrc={combo} alt="Chivas" ratio={329 / 172} />
            </div>
            <div className="p-registration_form_bottom">
              <Image imgSrc={imgFooter} alt="Chivas Hiball" ratio={371 / 41} />
            </div>
          </div>
        </Container>
      </div>
      <CustomModal isOpen={locationNotice.trigger}>
        <div className="popup-notice">
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">THÔNG BÁO</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            {(() => {
              switch (locationNotice.error) {
                case 'PERMISSION_DENIED':
                  return (
                    <>
                      Xin lỗi, bạn cần cho phép truy cập
                      <br />
                      vị trí để có thể tham gia
                      <br />
                      chương trình.
                      <br />
                      Xin vui lòng cấp quyền
                      <br />
                      truy cập vị trí trong phần cài đặt
                      <br />
                      điện thoại để có thể tiếp tục
                      <br />
                      tham gia.
                    </>
                  );
                case 'POSITION_UNAVAILABLE':
                  return (
                    <>
                      Không thể xác định vị trí của bạn.
                      <br />
                      Vui lòng thử lại.
                    </>
                  );
                default:
                  return (
                    <>
                      Đã hết thời gian xử lý.
                      <br />
                      Vui lòng thử lại
                    </>
                  );
              }
            })()}
          </Typography.Text>
          <div onClick={() => {
            dispatch(openLocationModal());
            setLocationNotice({ trigger: false, error: 'PERMISSION_DENIED' });
          }}
          >
            <Typography.Text extendClasses="popup-notice_location" fontweight="600">
              Hướng dẫn bật vị trí
            </Typography.Text>
          </div>
          <div
            className="popup-notice_confirm"
            role="button"
            onClick={() => setLocationNotice({ trigger: false, error: 'PERMISSION_DENIED' })}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐỒNG Ý</Typography.Text>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={!!globalErr}>
        <div className={`${mapModifiers('popup-notice', exist && 'exist')} error`}>
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">{exist ? 'Mã QR đã được sử dụng' : 'THÔNG BÁO'}</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            <span dangerouslySetInnerHTML={{ __html: globalErr || '' }} />
          </Typography.Text>
          <div
            className="popup-notice_confirm error"
            role="button"
            onClick={() => setGlobalErr(undefined)}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐÓNG</Typography.Text>
          </div>
        </div>
      </CustomModal>
      <CustomModal isOpen={condition}>
        <div className="p-home_policy">
          <Image imgSrc={boxModal} alt="Policy" ratio={343 / 563} />
          <div className="p-home_policy_content">
            <Typography.Text extendClasses="p-home_policy_title" fontweight="600">
              đIỀU Kiện nhận thưởng
            </Typography.Text>
            <Typography.Text extendClasses="p-home_policy_text">
              <p dangerouslySetInnerHTML={{ __html: contentRegistration || '' }} />
            </Typography.Text>
            <div className="p-home_policy_button">
              <Button
                variant="primary"
                primaryColor="#00E151"
                onClick={() => setCondition(false)}
              >
                <p className="p-home_policy-textButton">
                  Đóng
                </p>
              </Button>
            </div>
          </div>
        </div>
      </CustomModal>
    </PageLayout>
  );
};

export default Registration;
