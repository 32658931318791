import React, { useState } from 'react';
import { Typography } from 'tfc-components';

import CustomModal from 'components/organisms/Modal';
import PageLayout from 'components/organisms/PageLayout';
import useDidMount from 'hooks/useDidMount';
import { mappingErrorLocationGetter } from 'services/common/errors';
import { getLocation } from 'utils/functions';

const GetLocation: React.FC = () => {
  const [latlng, setLatLng] = useState({ lat: 0, lng: 0 });
  const [loading, setLoading] = useState(false);
  const [locationNotice, setLocationNotice] = useState({
    trigger: false,
    error: 'PERMISSION_DENIED' as LocationErrorType
  });
  useDidMount(() => {
    setLoading(true);
    getLocation().then((res) => {
      setLoading(false);
      setLatLng({ lat: res.lat, lng: res.long });
    }).catch((error: GeolocationPositionError) => {
      setLoading(false);
      setLocationNotice({ trigger: true, error: mappingErrorLocationGetter(error.code) });
    });
  });
  return (
    <PageLayout loading={loading}>
      <Typography.Text
        extendClasses="p-home_text"
        fontweight="700"
      >
        Lat:
        {' '}
        {latlng.lat}
        {' '}
        <br />
        Lng:
        {' '}
        {latlng.lng}
      </Typography.Text>
      <CustomModal isOpen={locationNotice.trigger}>
        <div className="popup-notice">
          <Typography.Text extendClasses="popup-notice_title" fontweight="600">THÔNG BÁO</Typography.Text>
          <Typography.Text extendClasses="popup-notice_content" fontweight="600">
            {(() => {
              switch (locationNotice.error) {
                case 'PERMISSION_DENIED':
                  return (
                    <>
                      Xin lỗi, bạn cần cho phép truy cập
                      <br />
                      vị trí để có thể tham gia
                      <br />
                      chương trình.
                      <br />
                      Xin vui lòng cấp quyền
                      <br />
                      truy cập vị trí trong phần cài đặt
                      <br />
                      điện thoại để có thể tiếp tục
                      <br />
                      tham gia.
                    </>
                  );
                case 'POSITION_UNAVAILABLE':
                  return (
                    <>
                      Không thể xác định vị trí của bạn.
                      <br />
                      Vui lòng thử lại.
                    </>
                  );
                default:
                  return (
                    <>
                      Đã hết thời gian xử lý.
                      <br />
                      Vui lòng thử lại
                    </>
                  );
              }
            })()}
          </Typography.Text>
          <div
            className="popup-notice_confirm"
            role="button"
            onClick={() => setLocationNotice({ trigger: false, error: 'PERMISSION_DENIED' })}
          >
            <Typography.Text extendClasses="popup-notice_textbtn" fontweight="900">ĐỒNG Ý</Typography.Text>
          </div>
        </div>
      </CustomModal>
    </PageLayout>
  );
};

export default GetLocation;
