import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';

import { getProfileService } from 'services/customer';
import { CustomerData } from 'services/customer/types';

interface UserState {
  profile?: CustomerData;
}

const initialState: UserState = {
};

export const getProfileAction = createAsyncThunk<
  CustomerData,
  string,
  { rejectValue: any }
>('userReducer/getProfileAction', async (phone, { rejectWithValue }) => {
  try {
    const res = await getProfileService(phone);
    return res.profileData;
  } catch (error) {
    return rejectWithValue(error as any);
  }
});

export const userSlice = createSlice({
  name: 'userReducer',
  initialState,
  reducers: {
    saveProfile($state, action: PayloadAction<CustomerData>) {
      $state.profile = action.payload;
    }
  },
  extraReducers(builder) {
    builder.addCase(getProfileAction.fulfilled, ($state, action) => {
      $state.profile = action.payload;
    });
  },
});

export const { saveProfile } = userSlice.actions;

export default userSlice.reducer;
